import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "../styles/contact.css";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_boo94xg", // Your Service ID from EmailJS
        "template_8tlbm8t", // Your Template ID from EmailJS
        form.current, // The current form
        "a3bb8ljYHK8T9lCNl" // Your Public Key from EmailJS
      )
      .then(
        (result) => {
          alert("Your message has been successfully submitted.");
          form.current.reset(); // Reset the form after successful submission
        },
        (error) => {
          console.log("Email sending failed...", error.text);
          alert("Failed to send the message, please try again.");
        }
      );
  };

  return (
    <div className="container">
      <h1 className="heading">Let's Connect</h1>

      <div className="main-div-form">
        <div className="form-main">
          <form ref={form} onSubmit={sendEmail}>
            <div className="main-inputs">
              <input
                type="text"
                name="user_name"
                required
                placeholder="Full name"
              />
              <input
                type="email"
                name="user_email"
                required
                placeholder="Email address"
              />
              <input
                type="text"
                name="subject"
                required
                placeholder="Subject"
              />
            </div>
            <textarea
              name="message"
              placeholder="How can I help you?"
              cols="30"
              rows="10"
              required
            ></textarea>
            <div className="form_btn">
              <button className="form_submit_btn" type="submit">
                <div className="svg-wrapper-1">
                  <div className="svg-wrapper">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path
                        fill="currentColor"
                        d="M1.946 9.315c-.522-.174-.527-.455.01-.634l19.087-6.362c.529-.176.832.12.684.638l-5.454 19.086c-.15.529-.455.547-.679.045L12 14l6-8-8 6-8.054-2.685z"
                      ></path>
                    </svg>
                  </div>
                </div>
                <span>Send</span>
              </button>
            </div>
          </form>
        </div>
        <div className="main_earth">
          <div id="earth" />
        </div>
      </div>
    </div>
  );
};

export default Contact;
