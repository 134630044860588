import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import "../styles/Home.css";
import Homesbg from "./homesbg";
import "../styles/media.css";
import triangle from "../images/purple_romb1.png";
import danielpic from "../images/transparentdanielpic.png";
import Aboutloader from "./aboutloader";
import Fade from "react-reveal/Fade";
import "../styles/skill.css";
import Skillsection from "./Skillsection";
import Contact from "./contact";
import Projects from "./Projects";

const Home = () => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos > 100) {
        setFadeIn(true);
      } else {
        setFadeIn(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div>
      <Fade bottom>
        <section className="section-home">
          <div id="home-container" className="container">
            <div className="home_cotent">
              <div className="home_txt">
                <h1 className="writing-animation">
                  Hi, I'm <span className="name">DANIEL</span>
                </h1>
                <h1 className="late-writing-animation">
                  a Front End <span className="name">Developer</span>{" "}
                </h1>
                <p className="para">
                  Resolving design problems, building smart user interfaces and
                  useful interactions, developing rich web applications and
                  seamless web experiences.
                </p>
                <Link
                  to="section-about"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="about-btn"
                >
                  About me
                </Link>
                <div className="triangle">
                  <img src={triangle} alt="" srcset="" />
                </div>
              </div>
              <div className="home-img">
                <Homesbg></Homesbg>
              </div>
            </div>
          </div>
        </section>
      </Fade>
      <Fade bottom when={fadeIn}>
        {/* section about  */}
        <section id="section-about" className="section-about">
          <div className="container">
            <div className="about_main">
              <div className="daniel_img " style={{ marginTop: "30px" }}>
                <img src={danielpic} alt="" />
              </div>

              <div className="about_content">
                <div className="loader_heading">
                  <h1 className="heading">Hi, I'm Daniel Web Developer</h1>

                  <Aboutloader></Aboutloader>
                </div>
                <p className="para aboutkeyword">
                  Front End Developer / Web Designer / Web Hosting / RESTFUL API
                  Integration
                </p>
                <p className="para">
                  Professionally connected with the web development industry.
                  Problem solver, well-organised person, loyal employee with
                  high attention to detail.
                </p>
                <Link
                  to="projects"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  className="about-btn"
                >
                  Projects
                </Link>
              </div>
            </div>
          </div>
        </section>
      </Fade>
      <Fade bottom when={fadeIn}>
        <section className="skill_section">
          <div className="container">
            <div className="skill_content">
              <span>A PROBLEM IS A CHANCE FOR YOU TO DO YOUR BEST.</span>
              <h1 className="heading">Skills & Experience</h1>
              <p className="para">
                HTML, CSS, JS, building small and medium web applications with
                Bootstrap and Sass, custom plugins, features, animations, and
                coding interactive layouts. I have also experience with one of
                the most popular framework React.js
              </p>
              <p className="para">
                Visit my{" "}
                <a href="https://www.linkedin.com/in/daniel-kouck%C3%BD-76979873/">
                  Linkedin
                </a>{" "}
                for more details.
              </p>
            </div>
            <Skillsection />
          </div>
        </section>
      </Fade>
      <Fade bottom when={fadeIn}>
        <section id="projects">
          <Projects />
        </section>
      </Fade>

      <Fade bottom when={fadeIn}>
        <div id="contact-section">
          <Contact></Contact>
        </div>
      </Fade>
    </div>
  );
};

export default Home;
