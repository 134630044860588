// Navbar.js
import React from "react";
import { Link } from "react-scroll";
import navlogo from "../images/navbarlogo.png";
import "../styles/Navbar.css";

const Navbar = () => {
  return (
    <div>
      <nav>
        <div>
          <img className="navlogo" src={navlogo} alt="" />
        </div>
        <div>
          {/* Use Link from react-scroll to smoothly scroll to the contact section */}
          <Link
            to="contact-section" // This should match the ID of the contact section
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="main-btn"
          >
            <span>Contact me</span>
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
