import React from "react";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="footermain">
          <p>© 2024 copywrites reserved Danielwebdev</p>
          <div>
            <a href="https://github.com/danielkoucky">
              <i class="fa-brands fa-github"></i>
            </a>
            <a href="https://github.com/danielkoucky">
              <i class="fa-brands fa-linkedin-in"></i>
            </a>
          </div>{" "}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
