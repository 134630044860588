import React from "react";
import "../styles/aboutloader.css";
import "../styles/media.css";

const Aboutloader = () => {
  return (
    <div>
      <span class="loader"></span>
    </div>
  );
};

export default Aboutloader;
